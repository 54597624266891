<template>
  <v-dialog
    v-model="dialog"
    content-class="modal-window-container"
  >
    <template #activator="{ on }">
      <v-btn
        rounded
        depressed
        color="primary"
        class="my-2 mr-2"
        data-cy="accept"
        v-on="on"
      >
        {{ buttonName }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span
          v-t="'invoices.checkoutBillingDetailsPaymentMethod'"
          class="headline"
          data-cy="payment-modal"
        />
        <v-spacer />
        <v-btn
          rounded
          depressed
          text
          @click="onModalClose"
        >
          <v-icon data-cy="payment-modal-close">
            {{ closeIcon }}
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <material-order-checkout
        :client-id="clientId"
        :order="order"
        :invoice-to-partner="invoiceToPartner"
      />
    </v-card>
  </v-dialog>
</template>
<script>
import { CLOSE_ICON } from '../../../constants/app'

export default {
  props: {
    clientId: {
      type: [String, Number],
      required: true
    },
    order: {
      type: Object,
      required: true
    },
    buttonName: {
      type: String,
      required: true
    },
    invoiceToPartner: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dialog: false,
      closeIcon: CLOSE_ICON
    }
  },
  methods: {
    onModalClose: function () {
      this.dialog = false
    },

    camelize (string) {
      return this.$options.filters.camelize(string)
    }
  }
}
</script>
